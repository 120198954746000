import AuthForm from 'components/AuthForm';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { STATE_LOGIN, STATE_FORGOT_PASSWORD_1, STATE_FORGOT_PASSWORD_2, STATE_PASSWORD_RECOVERY, STATE_TWO_FACTOR_AUTH_1, STATE_TWO_FACTOR_AUTH_2 } from '../components/AuthForm';

class AuthPage extends React.Component {
  handleAuthState = authState => {
    switch (authState) {
      case STATE_LOGIN:
        this.props.history.push('login');
        break;
      case STATE_FORGOT_PASSWORD_1:
        this.props.history.push('forgot-password-1');
        break;
      case STATE_FORGOT_PASSWORD_2:
        this.props.history.push('forgot-password-2');
        break;
      case STATE_PASSWORD_RECOVERY:
        this.props.history.push('recovery');
        break;
      case STATE_TWO_FACTOR_AUTH_1:
        this.props.history.push('two-factor-auth-1');
        break;
      case STATE_TWO_FACTOR_AUTH_2:
        this.props.history.push('two-factor-auth-2');
        break;
      default:
        break;
    }
  };

  render() {
    document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    return (
      <Row
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Col md={6} lg={4}>
          <Card body>
            <AuthForm
              authState={this.props.authState}
              onChangeAuthState={this.handleAuthState}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default AuthPage;
