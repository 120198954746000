import React from 'react';
import { MdClearAll } from 'react-icons/md';
import {
  Button,
  Nav,
  Navbar,
} from 'reactstrap';
import bn from 'utils/bemnames';
const bem = bn.create('header');
class Header extends React.Component {
  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {
    let isImpersonificating = false;
    let username = '';
    let impersonificatedUsername = '';
    try {
      const token = JSON.parse(
        Buffer.from(
          localStorage.getItem('token').split('.')[1],
          'base64'
        ).toString('ascii')
      );
      impersonificatedUsername = token.user.username;
      if (token.delegating_user) {
        isImpersonificating = true;
        username = token.delegating_user.username;
      } else {
        username = token.user.username;
      }
    } catch (_) {
      return;
    }

    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>

        <Nav navbar className={bem.e('nav-right')}>
          Ciao&nbsp;<b>{username}</b>{
            isImpersonificating ? (<span>&nbsp;(loggato come <b>{impersonificatedUsername}</b>)</span>) : null
          }
        </Nav>
      </Navbar>
    );
  }
}

export default Header;
