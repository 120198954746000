import logo200Image from 'assets/img/logo/logo_200.png';
import sidebarBgImage from 'assets/img/sidebar.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
import { App } from '../../App';
import {
  MdPermIdentity,
  MdLoyalty,
  MdList,
  MdModeEdit,
  MdMultilineChart,
  MdSettings,
  MdExitToApp,
} from 'react-icons/md';
import { FaServer } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  render() {
    let role = '';
    let isImpersonificating = false;
    let delegating_username = '';
    try {
      const token = JSON.parse(
        Buffer.from(
          localStorage.getItem('token').split('.')[1],
          'base64'
        ).toString('ascii')
      );
      role = token.user.role;
      if (token.delegating_user) {
        isImpersonificating = true;
        delegating_username = token.delegating_user.username;
      }
    } catch (_) {
      return;
    }
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img
                src={logo200Image}
                width="40"
                height="30"
                className="pr-2"
                alt=""
              />
              <span className="text-white">
                {process.env.REACT_APP_NAME} &trade;
              </span>
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {(role === 'SP' || role === 'CD') ? (<NavItem className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-userList`}
                className="text-uppercase"
                tag={NavLink}
                to="/users"
                activeClassName="active"
              >
                <MdPermIdentity className={bem.e('nav-item-icon')} />
                <span className="">Gestione {(role === 'SP') && 'CD' || 'SI'}</span>
              </BSNavLink>
            </NavItem>) : (
              (role === 'SI') && (<NavItem className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-userList`}
                  className="text-uppercase"
                  tag={NavLink}
                  to="/"
                  activeClassName="active"
                  exact={true}
                >
                  <MdLoyalty className={bem.e('nav-item-icon')} />
                  <span className="">Genera Timbro Digitale</span>
                </BSNavLink>
              </NavItem>)
            )}
            {(role === 'CD' || role === 'SI') ? (<NavItem className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-userList`}
                className="text-uppercase"
                tag={NavLink}
                to="/vds-list"
                activeClassName="active"
                exact={true}
              >
                <MdList className={bem.e('nav-item-icon')} />
                <span className="">Elenco Timbri Digitali</span>
              </BSNavLink>
            </NavItem>) : null}
            {(role === 'CD') ? (<NavItem className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-userList`}
                className="text-uppercase"
                tag={NavLink}
                to="/vds-template-editor"
                activeClassName="active"
                exact={true}
              >
                <MdModeEdit className={bem.e('nav-item-icon')} />
                <span className="">Modifica template Timbro Digitale</span>
              </BSNavLink>
            </NavItem>) : null}
            {(role === 'SP' || role === 'CD') ? (<NavItem className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-userList`}
                className="text-uppercase"
                tag={NavLink}
                to="/stats"
                activeClassName="active"
                exact={true}
              >
                <MdMultilineChart className={bem.e('nav-item-icon')} />
                <span className="">Statistiche</span>
              </BSNavLink>
            </NavItem>) : null}
            {(role === 'CD' && (process.env.REACT_APP_CD_SFTP_ENABLED === "true")) ? (<NavItem className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-userList`}
                className="text-uppercase"
                tag={NavLink}
                to="/sftp-servers"
                activeClassName="active"
                exact={true}
              >
                <FaServer className={bem.e('nav-item-icon')} />
                <span className="">Server SFTP</span>
              </BSNavLink>
            </NavItem>) : null}
            <NavItem className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-userList`}
                className="text-uppercase"
                tag={NavLink}
                to="/settings"
                activeClassName="active"
                exact={true}
              >
                <MdSettings className={bem.e('nav-item-icon')} />
                <span className="">Impostazioni</span>
              </BSNavLink>
            </NavItem>

            <NavItem className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-logout`}
                className="text-uppercase"
                tag={NavLink}
                to="#"
                activeClassName="active"
                onClick={() => {
                  if (isImpersonificating) {

                    fetch(process.env.REACT_APP_VDS_API_URL + '/auth/pseudo/0', {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                      }
                    }).then(async response => {
                      if (response.status === 200) {
                        response = await response.json();
                        localStorage.setItem('token', response);
                        window.location.reload();
                      } else throw new Error('Access denied');
                    }).catch(_ => {
                      window.notificationSystem.addNotification({
                        message: `Errore durante l'impersonazione dell'utente indicato!`,
                        level: 'error',
                      });
                      App.renewSessionToken();
                    });
                  } else {
                    fetch(process.env.REACT_APP_VDS_API_URL + '/auth/invalidate', {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                      }
                    }).finally(_ => {
                      localStorage.removeItem('token');
                      localStorage.removeItem('signPin');
                      window.location = process.env.REACT_APP_SOURCE_URL;
                    });
                  }
                }}
              >
                <MdExitToApp className={bem.e('nav-item-icon')} />
                <span className="">{isImpersonificating ? 'Torna a ' + delegating_username : 'LOGOUT'}</span>
              </BSNavLink>
            </NavItem>
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
