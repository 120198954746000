import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { STATE_LOGIN, STATE_FORGOT_PASSWORD_1, STATE_FORGOT_PASSWORD_2, STATE_PASSWORD_RECOVERY, STATE_TWO_FACTOR_AUTH_1, STATE_TWO_FACTOR_AUTH_2 } from './components/AuthForm';
import './styles/reduction.scss';
import publicKey from 'assets/public.jwt.pem';

const jwt = require('jsonwebtoken');
const ListUsersPage = React.lazy(() => import('pages/ListUsersPage'));
const AdvancedUserSettingsPage = React.lazy(() => import('pages/AdvancedUserSettingsPage'));
const CurrentUserSettingsPage = React.lazy(() => import('pages/CurrentUserSettingsPage'));
const NewUserPage = React.lazy(() => import('pages/NewUserPage'));
const NewVDSPage = React.lazy(() => import('pages/NewVDSPage'));
const LocateVDSPage = React.lazy(() => import('pages/LocateVDSPage'));
const ListVDSPage = React.lazy(() => import('pages/ListVDSPage'));
const EditVDSTemplatePage = React.lazy(() => import('pages/EditVDSTemplatePage'));
const StatsPage = React.lazy(() => import('pages/StatsPage'));
const SftpServersPage = React.lazy(() => import('pages/SftpServersPage'));
//use "const IconsPage = React.lazy(() => import('pages/IconsPage'));" to lazy load icons page

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split("//").slice(1).join('//').split('/').slice(1).join('/')}`;
};

class App extends React.Component {
  static loggedUser = null;
  loginRoutes = [
    ['login', STATE_LOGIN],
    ['forgot-password-1', STATE_FORGOT_PASSWORD_1],
    ['forgot-password-2', STATE_FORGOT_PASSWORD_2],
    ['recovery', STATE_PASSWORD_RECOVERY],
    ['two-factor-auth-1', STATE_TWO_FACTOR_AUTH_1],
    ['two-factor-auth-2', STATE_TWO_FACTOR_AUTH_2]
  ].map(scope => {
    return (<LayoutRoute
      exact
      path={"/" + scope[0]}
      layout={EmptyLayout}
      key={scope[0]}
      component={props => (
        <AuthPage
          authState={scope[1]}
          {...props} />
      )}
    />);
  });

  static lastRefreshTokenFailed = false;
  static renewSessionToken() {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_VDS_API_URL + '/auth/renew', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(async response => {
        // refresh token if it is valid
        if (response.status === 200) {
          console.log('Token refreshed');
          response = await response.json();
          // set token in local storage
          localStorage.setItem('token', response);
          document.cookie = `token=${response}; path=/`;
          App.loggedUser = jwt.verify(
            localStorage.getItem('token'),
            localStorage.getItem('publicKey')
          );
          if (App.lastRefreshTokenFailed) {
            App.lastRefreshTokenFailed = false;
            window.notificationSystem.addNotification({
              message: `Sessione utente aggiornata correttamente! Potrebbe essere necessario ricaricare la pagina!`,
              level: 'info',
            });
          }
        } else {
          console.log('Token expired');
          // token refresh failed, logout
          localStorage.removeItem('token');
          localStorage.removeItem('signPin');
          // delete token cookie
          document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          App.loggedUser = null;
          window.location = process.env.REACT_APP_SOURCE_URL;
        }
      }).catch(_ => {
        App.lastRefreshTokenFailed = true;
        // token refresh failed for network reasons, retry in 5 seconds
        window.notificationSystem.addNotification({
          message: `Errore durante l'aggiornamento della sessione utente! Nuovo tentativo in 5 secondi...`,
          level: 'error',
        });
        setTimeout(App.renewSessionToken, 5 * 1000);
      });
    }
  }

  componentDidMount() {
    if (!window.notificationSystem) {
      window.notificationSystem = {
        addNotification: (notification) => { console.log(`Notification system not yet initialized! Fall back on console.log() => (${notification.level}) ${notification.message} `); }
      }
    }
    App.renewSessionToken();
    setInterval(App.renewSessionToken, 150 * 1000);
  }

  render() {
    try {
      App.loggedUser = jwt.verify(
        localStorage.getItem('token'),
        localStorage.getItem('publicKey')
      );
    } catch (_) {
      // fetch the public key from server
      fetch(publicKey).then(res => res.text()).then(pubKey => {
        // update the public key in local storage
        // so next calls will use the cached key
        localStorage.setItem('publicKey', pubKey);
      });
    }

    let routes = [];

    if (!App.loggedUser) {
      // delete signPin from local storage if it exists
      localStorage.removeItem('signPin');

      // populate routes with the first 4 login routes
      routes = [...this.loginRoutes];
      routes.push(<LayoutRoute
        layout={EmptyLayout}
        key="default_route"
        component={props => (
          <AuthPage
            authState={STATE_LOGIN}
            {...props} />
        )}
      />
      );
    } else if (App.loggedUser.missing_otp_verification) {
      routes = [routes[(App.loggedUser.user.otp_status === 'ENABLED') ? 4 : 5], <LayoutRoute
        layout={EmptyLayout}
        key="default_route"
        component={props => (
          <AuthPage
            authState={(App.loggedUser.user.otp_status === 'ENABLED') ? STATE_TWO_FACTOR_AUTH_1 : STATE_TWO_FACTOR_AUTH_2}
            {...props} />
        )}
      />];
    } else {
      // handle different routes for logged in users
      switch (App.loggedUser.user.role) {
        case 'SP':
          routes = [...routes,
          <MainLayout key="main_layout_key" breakpoint={this.props.breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>
              <Route path="/users/edit-user/:userId" component={AdvancedUserSettingsPage} />
              <Route path="/users/new-user" component={NewUserPage} />
              <Route exact path="/users" component={ListUsersPage} />
              <Route exact path="/stats" component={StatsPage} />
              <Route exact path="/settings" component={CurrentUserSettingsPage} />
              {/*<Route exact path="/icons" component={IconsPage} />*/}
              <Route exact path="/" render={() => <Redirect to="/users" />} />
            </React.Suspense>
          </MainLayout>
          ];
          break;
        case 'CD':
          routes = [...routes,
          <MainLayout breakpoint={this.props.breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>
              <Route path="/users/edit-user/:userId" component={AdvancedUserSettingsPage} />
              <Route path="/users/new-user" component={NewUserPage} />
              <Route exact path="/users" component={ListUsersPage} />
              <Route exact path="/settings" component={CurrentUserSettingsPage} />
              <Route exact path="/sftp-servers" component={SftpServersPage} />
              <Route exact path="/vds-list" component={ListVDSPage} />
              <Route exact path="/vds-template-editor" component={EditVDSTemplatePage} />
              <Route exact path="/stats" component={StatsPage} />
              <Route exact path="/" render={() => <Redirect to="/users" />} />
            </React.Suspense>
          </MainLayout>
          ];
          break;
        case 'SI':
          routes = [...routes,
          <MainLayout breakpoint={this.props.breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>
              <Route exact path="/new-vds" component={NewVDSPage} />
              <Route path="/loc-vds/:vdsId" component={LocateVDSPage} />
              <Route exact path="/vds-list" component={ListVDSPage} />
              <Route exact path="/settings" component={CurrentUserSettingsPage} />
              <Route exact path="/" render={() => <Redirect to="/new-vds" />} />
            </React.Suspense>
          </MainLayout>
          ];
          break;
        default:
          break;
      }
    }

    return (
      <BrowserRouter basename={getBasename()}>
        <Switch>
          {
            routes
          }
        </Switch>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
export { App };
