import logo200Image from 'assets/img/logo/logo_200.png';
import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { IconWidget } from 'components/Widget';
import { MdWarning, MdVerifiedUser } from 'react-icons/md';

import speakeasy from 'speakeasy';
import QRCode from 'qrcode';

class AuthForm extends React.Component {
  handleLoginThenBranch = async function (response) {
    if (response.status === 200) {
      response = await response.json();
      // set token in local storage
      localStorage.setItem('token', response);
      // set token cookie
      document.cookie = `token=${response}; path=/`;
      // decode token by taking the second part of the token
      const token = JSON.parse(Buffer.from(response.split('.')[1], 'base64').toString('ascii'));
      if (token.user.otp_status === 'DISABLED') {
        // login successful, navigate lo dashboard
        window.location = process.env.REACT_APP_SOURCE_URL;
      } else {
        if (token.user.otp_status === 'ENABLED') {
          this.changeAuthState({
            authState: STATE_TWO_FACTOR_AUTH_1,
            messages: []
          })();
        } else {
          this.changeAuthState({
            authState: STATE_TWO_FACTOR_AUTH_2,
            messages: []
          })();
        }
      }
    } else {
      this.changeAuthState({
        authState: STATE_LOGIN,
        messages: [
          (<IconWidget
            bgColor='danger'
            icon={MdWarning}
            title='Accesso negato'
            subtitle='Username o password errati'
          />)
        ]
      })();
    }
  };

  handleCatchError = function (error) {
    this.changeAuthState({
      authState: STATE_LOGIN,
      messages: [
        (<IconWidget
          bgColor='danger'
          icon={MdWarning}
          title='Errore imprevisto'
          subtitle={error.toString()}
        />)
      ]
    });
  }
  changeAuthState = function (authState) {
    this.props.messages.length = 0;
    const messages = authState.authState ? authState.messages : [];
    messages.forEach(message => { this.props.messages.push(message); });
    authState = authState.authState ? authState.authState : authState;
    return event => {
      if (event) event.preventDefault();
      this.props.onChangeAuthState(authState);
    }
  }

  handleLogin = event => {
    const ctx = this;
    event.preventDefault();
    // get username and password from form
    const username = document.getElementsByName('Username')[0].value;
    const password = document.getElementsByName('Password')[0].value;
    fetch(process.env.REACT_APP_VDS_API_URL + '/auth/request', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      })
    }).then(async response => ctx.handleLoginThenBranch(response)).catch(error => ctx.handleCatchError(error));
  };

  handleSubmitRecoveryMail = event => {
    const ctx = this;
    event.preventDefault();
    const email = document.getElementsByName('Email')[0].value;
    fetch(process.env.REACT_APP_VDS_API_URL + '/auth/request_reset', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      })
    }).then(_ => {
      ctx.changeAuthState(STATE_FORGOT_PASSWORD_2)();
    });
  };

  handleLogin2FA = event => {
    const ctx = this;
    event.preventDefault();
    const otp = document.getElementsByName('OTP')[0].value;

    fetch(process.env.REACT_APP_VDS_API_URL + '/auth/request', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({ otp })
    }).then(async response => ctx.handleLoginThenBranch(response)).catch(error => ctx.handleCatchError(error));
  };

  handleLogin2FA = event => {
    const ctx = this;
    event.preventDefault();
    const otp = document.getElementsByName('OTP')[0].value;

    fetch(process.env.REACT_APP_VDS_API_URL + '/auth/request', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({ otp })
    }).then(async response => {
      if (response.status === 200) {
        response = await response.json();
        localStorage.setItem('token', response);
        // set token cookie
        document.cookie = `token=${response}; path=/`;
        window.location = process.env.REACT_APP_SOURCE_URL;
      } else {
        ctx.changeAuthState({
          authState: ctx.props.authState,
          messages: [
            (<IconWidget
              bgColor='warning'
              icon={MdWarning}
              title='Attenzione'
              subtitle='Codice OTP non corretto!'
            />)
          ]
        })();
      }
    }).catch(error => ctx.handleCatchError(error));
  };

  handlePasswordReset = event => {
    const ctx = this;
    event.preventDefault();

    const token = document.getElementsByName('token')[0].value;
    const password = document.getElementsByName('NewPassword')[0].value;
    const password2 = document.getElementsByName('NewPassword2')[0].value;

    if (password === password2) {
      if (password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.{8,})/)) {
        fetch(process.env.REACT_APP_VDS_API_URL + '/auth/reset_password', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          },
          body: JSON.stringify({
            token,
            password,
          })
        }).then(async response => {
          if (response.status === 200) {
            ctx.changeAuthState({
              authState: STATE_LOGIN,
              messages: [
                (<IconWidget
                  bgColor='success'
                  icon={MdVerifiedUser}
                  title='Operazione completata'
                  subtitle='La nuova password è stata impostata correttamente!'
                />)
              ]
            })();
          } else {
            response = await response.json();
            switch (response.message) {
              case "NEWPWD_EQU_OLDPWD":
                ctx.changeAuthState({
                  authState: STATE_PASSWORD_RECOVERY,
                  messages: [
                    (<IconWidget
                      bgColor='warning'
                      icon={MdWarning}
                      title='Attenzione'
                      subtitle='La nuova password non può essere uguale alla vecchia'
                    />)
                  ]
                })();
                break;
              case "PWD_FAILS_MIN_REQS":
                ctx.changeAuthState({
                  authState: STATE_PASSWORD_RECOVERY,
                  messages: [
                    (<IconWidget
                      bgColor='warning'
                      icon={MdWarning}
                      title='Attenzione'
                      subtitle='La password deve contenere almeno 8 caratteri, una lettera maiuscola, una lettera minuscola, un numero e un carattere speciale'
                    />)
                  ]
                })();
                break;
              case "ACCESS_DENIED":
                ctx.changeAuthState({
                  authState: STATE_LOGIN,
                  messages: [
                    (<IconWidget
                      bgColor='danger'
                      icon={MdWarning}
                      title='Accesso negato'
                      subtitle='Token scaduto'
                    />)
                  ]
                })();
                break;
              default:
                ctx.changeAuthState({
                  authState: STATE_LOGIN,
                  messages: [
                    (<IconWidget
                      bgColor='danger'
                      icon={MdWarning}
                      title='Errore imprevisto'
                      subtitle={response.message}
                    />)
                  ]
                })();
                break;
            }
          }
        }).catch(error => ctx.handleCatchError(error));
      } else {
        ctx.changeAuthState({
          authState: STATE_PASSWORD_RECOVERY,
          messages: [
            (<IconWidget
              bgColor='danger'
              icon={MdWarning}
              title='Errore'
              subtitle='La password deve contenere almeno 8 caratteri, una lettera maiuscola, una lettera minuscola, un numero e un carattere speciale'
            />)
          ]
        })();
        return;
      }
    } else {
      ctx.changeAuthState({
        authState: STATE_PASSWORD_RECOVERY,
        messages: [
          (<IconWidget
            bgColor='danger'
            icon={MdWarning}
            title='Attenzione'
            subtitle='Le password non corrispondono!'
          />)
        ]
      })();
    }



  };

  loginForm = () => {
    return (
      <Form action="#" onSubmit={this.handleLogin}>
        <div className="text-center pb-4">
          <img
            src={logo200Image}
            className="rounded"
            style={{ width: 60, height: 60, cursor: 'pointer' }}
            alt="logo"
          />
        </div>
        {this.props.messages.map(message => message)}
        <FormGroup>
          <Label for="Username">Username</Label>
          <Input name="Username" type="text" placeholder="Username" />
        </FormGroup>
        <FormGroup>
          <Label for="Password">Password</Label>
          <Input name="Password" type="password" placeholder="Password" />
        </FormGroup>
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          type="submit"
          onClick={this.handleLogin}>
          ACCEDI
        </Button>
        <br />

        <div className="text-center pt-1">
          <h6>
            <a href="#" onClick={this.changeAuthState(STATE_FORGOT_PASSWORD_1)}>
              Hai dimenticato la password?
            </a>
          </h6>
        </div>
      </Form>
    );
  }
  forgotPwd1Form = () => {
    return (
      <Form action="#" onSubmit={this.handleSubmitRecoveryMail}>
        <div className="text-center pt-1">
          <h1>
            PASSWORD DIMENTICATA
          </h1>
          <h6>
            Inserisci l'email associata all'account per il quale richiedi il reset della password.
          </h6>
        </div>
        <FormGroup>
          <Label for="Email">Email</Label>
          <Input name="Email" type="email" placeholder="email@example.com" />
        </FormGroup>
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          type="submit"
          onClick={this.handleSubmitRecoveryMail}>
          INVIA LINK DI RESET
        </Button>
        <br />

        <div className="text-center pt-1">
          <h6>
            <a href="#" onClick={this.changeAuthState(STATE_LOGIN)}>
              Torna alla schermata di login
            </a>
          </h6>
        </div>
      </Form>
    );
  }
  forgotPwd2Form = () => {
    return (
      <div>
        <div className="text-center pt-1">
          <h1>
            PASSWORD DIMENTICATA
          </h1>
          <h6>
            Se esiste un account con tale email, verrà inviata un'email con ulteriori informazioni.
          </h6>
        </div>
        <br />
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          type="submit"
          onClick={this.changeAuthState(STATE_LOGIN)}>
          TORNA AL LOGIN
        </Button>
        <br />
      </div>
    );
  }
  twoFactor1Form = () => {
    return (
      <Form action="#" onSubmit={this.handleLogin2FA}>
        <div className="text-center pt-1">
          <h1>
            SECONDO FATTORE
          </h1>
          {this.props.messages.map(message => message)}
          <h6>
            Inserisci il codice di autenticazione generato dall'app Authenticator.
          </h6>
        </div>
        <FormGroup>
          <Label for="OTP">Codice OTP</Label>
          <Input name="OTP" type="text" placeholder="######" pattern="[0-9]{6}" autocomplete="off" />
        </FormGroup>
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          type="submit"
          onClick={this.handleLogin2FA}>
          PROSEGUI
        </Button>
        <br />
      </Form>
    );
  }
  twoFactor2Form = () => {
    let otp_url = '';
    try {
      const token = JSON.parse(Buffer.from(localStorage.getItem('token').split('.')[1], 'base64').toString('ascii'));
      if (!token) throw new Error('No token');
      otp_url = speakeasy.otpauthURL(
        {
          secret: token.user.otp_secret,
          label: 'VDS%20Generator'
        });
    } catch (_) {
      this.changeAuthState({
        authState: STATE_LOGIN,
        messages: [
          (<IconWidget
            bgColor='danger'
            icon={MdWarning}
            title='Errore imprevisto'
            subtitle='Errore imprevisto. Effettua il login.'
          />)
        ]
      })();
      return null;
    }
    QRCode.toDataURL(otp_url).then(url => {
      // update QR_otp src attribute
      document.getElementById('QR_otp').src = url;
    });

    return (
      <Form action="#" onSubmit={this.handleLogin2FA}>
        <div className="text-center pt-1">
          <h1>
            ABILITA 2FA PER PROSEGUIRE
          </h1>
          {this.props.messages.map(message => message)}
          <img style={{ width: '250px' }} id="QR_otp" />
          <h6>
            Inserisci il codice di autenticazione generato dall'app Authenticator per abilitare l'OTP.
          </h6>
        </div>
        <FormGroup>
          <Label for="OTP">Codice OTP</Label>
          <Input name="OTP" type="text" placeholder="######" pattern="[0-9]{6}" autocomplete="off" />
        </FormGroup>
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          type="submit"
          onClick={this.handleLogin2FA}>
          ATTIVA
        </Button>
        <br />
      </Form>
    );
  }
  pwdRecoveryForm = () => {
    return (
      <Form action="#" onSubmit={this.handlePasswordReset}>
        <div className="text-center pt-1">
          <h1>
            RECUPERO PASSWORD
          </h1>
        </div>
        {this.props.messages.map(message => message)}
        <input type="hidden" name="token" value={new URLSearchParams(window.location.search).get('token')} />
        <FormGroup>
          <Label for="NewPassword">Nuova password</Label>
          <Input name="NewPassword" type="password" placeholder="Password" />
        </FormGroup>
        <FormGroup>
          <Label for="NewPassword2">Ripeti nuova password</Label>
          <Input name="NewPassword2" type="password" placeholder="Password" />
        </FormGroup>
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          type="submit"
          onClick={this.handlePasswordReset}>
          CONFERMA
        </Button>
        <br />

        <div className="text-center pt-1">
          <h6>
            <a href="#" onClick={this.changeAuthState(STATE_LOGIN)}>
              Torna alla schermata di login
            </a>
          </h6>
        </div>
      </Form>
    );
  }

  render() {
    switch (this.props.authState) {
      case STATE_FORGOT_PASSWORD_1:
        return this.forgotPwd1Form();
      case STATE_FORGOT_PASSWORD_2:
        return this.forgotPwd2Form();
      case STATE_TWO_FACTOR_AUTH_1:
        return this.twoFactor1Form();
      case STATE_TWO_FACTOR_AUTH_2:
        return this.twoFactor2Form();
      case STATE_PASSWORD_RECOVERY:
        return this.pwdRecoveryForm();
      default:
        return this.loginForm();
    }
  }
}


export const STATE_LOGIN = 'LOGIN';
export const STATE_FORGOT_PASSWORD_1 = 'FORGOT_PASSWORD_1';
export const STATE_FORGOT_PASSWORD_2 = 'FORGOT_PASSWORD_2';
export const STATE_PASSWORD_RECOVERY = 'PASSWORD_RECOVERY';
export const STATE_TWO_FACTOR_AUTH_1 = 'TWO_FACTOR_AUTH_1';
export const STATE_TWO_FACTOR_AUTH_2 = 'TWO_FACTOR_AUTH_2';

AuthForm.defaultProps = {
  authState: 'LOGIN',
  messages: []
};

export default AuthForm;
